import { getTracking } from '@vp/tracking'

const DELAY_BEFORE_NAVIGATING_IN_MS = 300

const createOnClickTrackingHandler = (trackingConfig, href, openInNewTab) => {
  if (!trackingConfig) {
    return undefined
  }

  return event => {
    stopNavigation(event)
    track(trackingConfig)

    if (href) {
      setTimeout(() => navigate(href, openInNewTab), DELAY_BEFORE_NAVIGATING_IN_MS)
    }
  }
}

const stopNavigation = event => event.preventDefault()

const track = trackingConfig => {
  const { eventName, properties } = trackingConfig
  const tracking = getTracking()
  if (tracking) {
    tracking.track(eventName, properties, {})
  } else {
    const options = { once: true }
    window.addEventListener(
      'trackingReady',
      () => {
        const tracking = getTracking()
        tracking && tracking.track(eventName, properties, {})
      },
      options
    )
  }
}

const navigate = (href, openLinkNewTab) => {
  if (openLinkNewTab) {
    window.open(href, '_blank', 'noopener noreferrer')
  } else {
    window.location.href = href
  }
}

export { createOnClickTrackingHandler }
