import React from 'react'
import { useLogger, useSwanStyleKeys, useUserContext, useSwanFontKeys } from '@vp/ubik-context'
import { BannerWrapper, ITrackingProps, DefaultContentfulBannerWrapperProps } from '@vp/banner-wrapper'
import { BoundedContent, Column, Row, SWAN_STYLE_KEY_MAP, SwanFontNameEnum } from '@vp/swan'
import { getCtaTrackingConfig } from './Analytics/trackingConfig'
import { createOnClickTrackingHandler } from './Analytics/trackingUtils'
import { TrackingPageZone } from './Analytics/trackingTypes'
import { ContentfulLink, ContentfulContextProvider, DebugSection } from '@vp/mcs-react-tools'
import localizations from '../localizations/locales/index'

export interface IBannerProps {
  bannerProps: DefaultContentfulBannerWrapperProps[]
  usesMap?: boolean
  locale: string
  bannerPosition: string
  contentfulMetadata: {
    spaceId?: string,
    environment: string
    contentful_id: string,
    contentfulName: string,
  },
  debugData: any;
}

export const Banner = ({ bannerProps, bannerPosition, locale, contentfulMetadata, debugData, usesMap }: IBannerProps) => {
  const logger = useLogger()
  const userContext = useUserContext()

  const trackingPageZone = TrackingPageZone[bannerPosition]
  const sectionId = localizations[locale.toLocaleLowerCase()][bannerPosition]

  useSwanFontKeys([SwanFontNameEnum.TiemposRegular])

  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.banner,
  ])

  logger.log('Rendering fragment for a locale', userContext.locale)
  const trackingMethod = (event: React.MouseEvent<Element, MouseEvent>, { destinationUrl, linkIdentifier, ctaPosition, openInNewTab }: ITrackingProps) => {
    const trackingConfig = getCtaTrackingConfig({
      sourcePagePath: '/offers',
      destinationUrl: destinationUrl ?? '',
      pageZone: trackingPageZone,
      linkIdentifier,
      ctaPosition,
    })
    if (!trackingConfig) {
      return
    }
    const trackingAndNavigation = createOnClickTrackingHandler(trackingConfig, destinationUrl, openInNewTab)
    if (trackingAndNavigation) {
      trackingAndNavigation(event)
    }
  }
  const search = typeof window !== 'undefined' ? window.location.search : ''

  return (
    bannerProps && (
      <ContentfulContextProvider space={contentfulMetadata?.spaceId || ''}>
        {!usesMap && (
          <>
            <ContentfulLink id={contentfulMetadata?.contentful_id} />
            <DebugSection
              contentfulMetadata={contentfulMetadata}
              availability={debugData}
              search={search}
            />
          </>
        )}
        <BoundedContent>
          <Row marginBottom='2'>
            <Column span={12} padding={0}>
              <BannerWrapper id={sectionId} bannerProps={bannerProps} trackingHandler={trackingMethod} locale={locale} />
            </Column>
          </Row>
        </BoundedContent>
      </ContentfulContextProvider>
    )
  )
}
